:root {
  --primary-bg: #242526;
  --secondary-bg: #fff;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --border-radius: 8px;
  --speed: 500ms;
}

.NavbarItem {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 80px;
  border-radius: 0px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  z-index: 50;
}
.logo {
  color: #000;
  justify-self: start;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
  margin-top: 19px;
}

.nav-links {
  text-decoration: none;
  color: #000;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.nav-links-In {
  margin-left: 50px;
  text-decoration: none;
  color: #000;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}

.nav-links-In:hover {
  background: rgb(226, 226, 226);
  color: rgb(77, 77, 77);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.nav-links i {
  padding-right: 10px;
}
.nav-links:hover {
  background: rgb(226, 226, 226);
  color: rgb(77, 77, 77);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.fa-bars,
.fa-times {
  color: rgb(255, 255, 255);
  padding: 8px;
  background-color: #dc3545;
  border-radius: 5px;
}

.menu-icons {
  display: none;
}

.nav-links-mobile {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #0078ff;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.nav-links-mobile:hover {
  color: rgb(8, 8, 8);
  background-color: #278afa;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .nav-menu.active {
    left: 0%;
  }

  .nav-links {
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: #222;
  }

  .nav-links:hover {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
  }

  .menu-icons {
    display: block;
  }
  .NavbarItem {
    justify-content: space-between;
  }
  .nav-links-mobile {
    padding: 1.5rem;
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    width: 80%;
    margin: auto;
  }
}

.login-card-form input[type="text"],
.login-card-form input[type="password"],
.login-card-form input[type="email"],
.login-card-form input[type="tel"] {
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgb(96, 96, 96);
  padding: 1rem 1.5rem;
  padding-left: calc(1rem * 3.5);
  border-radius: 100px;
  width: 100%;
  transition: background 0.5s;
}

.profile-l {
  position: relative;
  margin-left: 80px;
}

.profile-l a {
  display: flex;
  height: 40px;
  margin-top: 10px;
  align-items: center;
  text-align: right;
}

.profile-l a span {
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 65px;
  right: 0px;
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 200px;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 0px;
  height: 0px;
  width: 0px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active {
  display: block;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  display: none;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.img-profile {
  width: 45px;
  height: 45px;
  margin-left: 10px;
}
.dropdownItem {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.dropdownItem img {
  max-width: 30px;
  height: 30px;
  margin-right: 5px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  max-width: 100px;
  margin-left: 10px;
  margin-top: 0;
  transition: var(--speed);
  text-decoration: none;
  color: var(--text-hover);
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-text-color);
  line-height: 1.2rem;
}

h3 span {
  font-size: 14px;
  color: #7e7e7e;
  font-weight: 400;
  margin-left: 10x;
}

.dropdown-menu ul li:hover a {
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.form-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-item p {
  color: red;
}

.isvalid {
  margin: 0;
}
