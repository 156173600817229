.Minicourses {
  max-width: 1280px;
  margin: 0 auto;
}
.container-Minicourses {
  margin-top: 90px;
}
.MinicoursesTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.Minicourses-r {
  width: 20%;
  margin: 10px;
  padding: 0.5rem;
  border: 1px solid #939393;
  border-radius: 10px;
}
.Minicourses-r iframe {
  width: 100%;
}

.Minicourses-r p {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 700;
}

.Minicourses-l {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 10px;
  padding: 10px 30px;
  border: 1px solid #939393;
  border-radius: 10px;
}

.TableShowData {
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.TableShowCourses {
  display: table;
  align-items: center;
  vertical-align: middle;
}

.FindMiniCourse {
  display: flex;
  width: 100%;
}

.TableShowData thead,
.TableShowData tbody {
  border: 0.5rem solid #258bff;
}

.TableShowData thead tr {
  background: #258bff;
  color: white;
}

.TableShowData tbody tr {
  font-size: 16px;
  font-weight: 500;
}

.TableShowData tbody tr #th4 {
  background: white;
  padding: 0;
  margin: 0;
}
