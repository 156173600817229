.QuizsContainer {
  flex: 4;
  background-color: rgb(193, 221, 241);
}

.QuizsTitle {
  display: flex;
}
.textQuizsTitle {
  margin-left: 100px;
  font-size: 40px;
  font-weight: 600;
  color: #2077d8;
}

.tabQuizContainer {
  display: flex;
  margin-top: 20px;
}

.AddQuizContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  height: 250px;
  width: 200px;
  padding: 1rem;
  border-radius: 5%;
  border: 3px dashed green;
}

.IconAddQuiz {
  color: green;
  font-size: 70px;
}

.AddQuizContainer :hover {
  color: #939393;
}

.EditQuizContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  height: 250px;
  width: 200px;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5%;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 20px;
}

.ImgQuizContainer {
  position: absolute;
  top: 20px;
  width: 120px;
  height: 120px;
}

.SkillsTitle {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  margin: 6px;
  bottom: 60px;
}

.BtnHead {
  position: absolute;
  bottom: 10px;
}

.BtnEditContainer {
  margin: 1px;
  padding: 7px 20px 7px 20px;
  border: none;
  border-radius: 16px;
  background-color: #0078ff;
  color: white;
  font-weight: 600;
  font-size: 16px;
}
